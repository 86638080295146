import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import {Database,set,ref,update} from '@angular/fire/database';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  email:string='';
  password:string='';
  username:string='';
  role:string='';
  constructor(private auth:AuthService,private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      // Redirect to dashboard
      this.router.navigate(['/dashboard']);
      return;
    }
  }

  register(){
    if(this.email == ''){
      alert('pease insert email');
      return
    }
    if(this.password == ''){
      alert('pease insert password');
      return
    }
    if(this.username == ''){
      alert('pease insert username');
      return
    }
    if(this.role == ''){
      alert('pease insert role');
      return
    }
    this.auth.register(this.email,this.password,this.role);
  }
}
