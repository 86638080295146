<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
                    <!-- Light Logo icon -->
                    <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo">
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                    <!-- dark Logo text -->
                    <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo">
                    <!-- Light Logo text -->
                    <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage">
                </span> </a>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->

        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'"
            fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches">

            <app-sidebar></app-sidebar>


        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">

            <div class="page-content">

                <router-outlet><app-spinner></app-spinner></router-outlet>

            </div>

        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="padding: 10px;">
            <form>
                <div class="form-group" style="margin-top: 20px;">
                    <label for="exampleInputEmail1">Username</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        [(ngModel)]="username" placeholder="Enter username">
                </div>
                <div class="form-group" style="margin-top: 20px;">
                    <label for="exampleInputEmail1">passward</label>
                    <input type="email" class="form-control" [(ngModel)]="password" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter pasward">
                </div>
                <div class="form-group" style="margin-top: 20px;">
                    <label for="exampleInputEmail1">Name</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="name"
                        aria-describedby="emailHelp" placeholder="Enter name">
                </div>
                <div class="form-group" style="margin-top: 20px;">
                    <label for="exampleInputEmail1">Email</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="email"
                        aria-describedby="emailHelp" placeholder="Enter email">
                </div>

            </form>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary" (click)="adddistributers()">Submit</button>
            </div>
        </div>
    </div>
</div>

<!-- player add form -->
<div class="modal fade" id="exampleModaladdplayer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Player</h5>
                    
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input type="text" class="form-control" id="username" placeholder="Enter username" [(ngModel)]="playerUsername">
                        </div>
                        <div class="form-group">
                            <label for="email">Email address</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter email" [(ngModel)]="playerEmail">
                        </div>
                        <div class="form-group">
                            <label for="passward">Password</label>
                            <input type="password" class="form-control" id="passward" placeholder="Enter passward" [(ngModel)]="playerPassword">
                        </div>
                        <div class="form-group">
                            <label for="agent">Agent</label>
                            <select  class="form-control" id="agent" placeholder="Enter agent name" [(ngModel)]="playerAgent">
                                <option value="1" *ngFor="let data of agents" [value]="data.id">{{data.name}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="subditributer">Sub-distributor</label>
                            <select  class="form-control" id="subditributer" placeholder="Enter subditributer name" [(ngModel)]="playerSubdistributer">
                                <option value="1">subditributer</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="distrubuter">Distributor</label>
                            <select  class="form-control" id="Distributer" placeholder="Enter Distributer name" [(ngModel)]="playerDistributer">
                                <option value="1">Distributer</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" (click)="addplayer()">Submit</button>
                </div>
            </div>
        </div>
    </div>

    <!-- subdistributor add form -->
<div class="modal fade" id="exampleModaladdsubdistributor" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Sub Distributer</h5>
            
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" class="form-control" id="username" placeholder="Enter username" [(ngModel)]="subdisUsername">
                </div>
                <div class="form-group">
                    <label for="username">Name</label>
                    <input type="text" class="form-control" id="username" placeholder="Enter username" [(ngModel)]="subdisName">
                </div>
                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" id="email" placeholder="Enter email" [(ngModel)]="subdisEmail">
                </div>
                <div class="form-group">
                    <label for="passward">Password</label>
                    <input type="password" class="form-control" id="passward" placeholder="Enter passward" [(ngModel)]="subdisPassword">
                </div>
                <div class="form-group">
                    <label for="distrubuter">Distributor</label>
                    <select  class="form-control" id="Distributer" placeholder="Enter Distributer name" [(ngModel)]="subdisDistributer">
                        <option value="1" *ngFor="let data of distributors" [value]="data.id">{{data.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="commision">Commision</label>
                    <input type="number" class="form-control" id="commision" placeholder="Enter commision" [(ngModel)]="subdisCommision">
                </div>
                <div class="form-group">
                    <label for="credit">Credit</label>
                    <input type="number" class="form-control" id="credit" placeholder="Enter credit" [(ngModel)]="subdisCredit">
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" (click)="addsubdistributor()">Submit</button>
        </div>
    </div>
</div>
</div>

 <!-- agent add form -->
 <div class="modal fade" id="exampleModaladdagent" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
 aria-hidden="true">
 <div class="modal-dialog" role="document">
     <div class="modal-content">
         <div class="modal-header">
             <h5 class="modal-title" id="exampleModalLabel">Add Agent</h5>
         </div>
         <div class="modal-body">
             <form>
                 <div class="form-group">
                     <label for="username">Username</label>
                     <input type="text" class="form-control" id="username" placeholder="Enter username" [(ngModel)]="agentUsername">
                 </div>
                 <div class="form-group">
                     <label for="username">Name</label>
                     <input type="text" class="form-control" id="username" placeholder="Enter username" [(ngModel)]="agentName">
                 </div>
                 <div class="form-group">
                     <label for="email">Email address</label>
                     <input type="email" class="form-control" id="email" placeholder="Enter email" [(ngModel)]="agentEmail">
                 </div>
                 <div class="form-group">
                     <label for="passward">Password</label>
                     <input type="password" class="form-control" id="passward" placeholder="Enter passward" [(ngModel)]="agentPassword">
                 </div>
                 <div class="form-group">
                     <label for="distrubuter">Distributor</label>
                     <select  class="form-control" id="Distributer" placeholder="Enter Distributer name" [(ngModel)]="agentsubdisDistributer">
                         <option value="1" *ngFor="let data of subdistributors" [value]="data.id">{{data.name}}</option>
                     </select>
                 </div>
                 <div class="form-group">
                     <label for="commision">Commision</label>
                     <input type="number" class="form-control" id="commision" placeholder="Enter commision" [(ngModel)]="agentCommision">
                 </div>
                 <div class="form-group">
                     <label for="credit">Credit</label>
                     <input type="number" class="form-control" id="credit" placeholder="Enter credit" [(ngModel)]="agentCredit">
                 </div>
             </form>
         </div>
         <div class="modal-footer">
             <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
             <button type="button" class="btn btn-primary" (click)="addagents()">Submit</button>
         </div>
     </div>
 </div>
 </div>

  <!-- request add form -->
  <div class="modal fade" id="exampleModalrequest" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Send Request</h5>
          </div>
          <div class="modal-body">
              <form>
                  <div class="form-group">
                      <label for="username">Amount</label>
                      <input type="text" class="form-control" id="amount" placeholder="Enter amount" name="amount" [(ngModel)]="amount">
                  </div>
              </form>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" (click)="sendrequest()">Submit</button>
          </div>
      </div>
  </div>
  </div>