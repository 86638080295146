import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string='';
password:string='';
  constructor(private auth:AuthService, private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      // Redirect to dashboard
      this.router.navigate(['/dashboard']);
      return;
    }
  }

  login(){
    if(this.email == ''){
      alert('please enter all values');
      return;
    }
    if(this.password == ''){
      alert('please ent password');
    return
    }

    this.auth.login(this.email,this.password);
    this.email='';
    this.password='';

  }
}
