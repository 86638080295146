import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { state: 'players', type: 'link', name: 'Player', icon: 'crop_7_5' },
  { state: 'distributers', type: 'link', name: 'Distributers', icon: 'people-fill' },
  { state: 'subdistributor', type: 'link', name: 'Sub Distributor', icon: 'people-fill' },
  { state: 'agents', type: 'link', name: 'Agents', icon: 'people-fill' },
  { state: 'cards', type: 'link', name: 'Cards', icon: 'people-fill' },
  { state: 'transaction', type: 'link', name: 'Transactions', icon: 'view_comfy' },
  { state: 'request', type: 'link', name: 'Requests', icon: 'view_comfy' },
  // { state: 'lists', type: 'link', name: 'Player History', icon: 'view_list' },
  // { state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
  // { state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
  // { state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
  // {
  //   state: 'expansion',
  //   type: 'link',
  //   name: 'Todays Summary',
  //   icon: 'vertical_align_center'
  // },
  // { state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
  // { state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
  // {
  //   state: 'progress-snipper',
  //   type: 'link',
  //   name: 'Progress snipper',
  //   icon: 'border_horizontal'
  // },
  // {
  //   state: 'progress',
  //   type: 'link',
  //   name: 'Progress Bar',
  //   icon: 'blur_circular'
  // },
  // {
  //   state: 'dialog',
  //   type: 'link',
  //   name: 'Dialog',
  //   icon: 'assignment_turned_in'
  // },
  // { state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
  // { state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
  // { state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
  // {
  //   state: 'slide-toggle',
  //   type: 'link',
  //   name: 'Slide Toggle',
  //   icon: 'all_inclusive'
  // }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    const role = localStorage.getItem('role');
    if (role === 'distributor') {
      // if the user has the role of "distributer", show the "Distributers" menu item
      return MENUITEMS.filter(item => item.state !== 'distributers');
    } 
    else if(role === 'subdistributor'){
      return MENUITEMS.filter(item => item.state !== 'distributers' &&  item.state !== 'subdistributor');
    }
    else if(role === 'agent'){
      return MENUITEMS.filter(item => item.state !== 'distributers' &&  item.state !== 'subdistributor'  &&  item.state !== 'agents');
    }
    else {
      // for all other roles, show all the menu items
      return MENUITEMS;
    }
  }
}
