import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, AfterViewInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AngularFireDatabase } from '@angular/fire/compat/database'
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../../shared/auth.service';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private authService: AuthService
  ) {
    if(!localStorage.getItem('token')){
      this.authService.logout();
    }
    this.authService.getUserId().then((uid: any) => {
      const uiid = localStorage.getItem('uid');
      console.log(uiid);
      if (uid == 'undefined') {
        this.authService.logout();
      }
      if (localStorage.getItem('role') == 'admin') {
        this.adminuid = uiid;

      }
      if (localStorage.getItem('role') == 'distributor') {
        this.db.object('15roulate/distributors/' + uiid).valueChanges().subscribe((data: any) => {
          this.adminuid = data.admin;
        });
      }
      if (localStorage.getItem('role') == 'subdistributor') {
        this.db.object('15roulate/subdistributer/' + uiid).valueChanges().subscribe((data: any) => {
          this.adminuid = data.admin;
        });
      }
      if (localStorage.getItem('role') == 'agent') {
        this.db.object('15roulate/agents/' + uiid).valueChanges().subscribe((data: any) => {
          this.adminuid = data.admin;
        });
      }

    });
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  username: any;
  email: any;
  name: any;
  password: any;
  adminuid: any;
  playerUsername: string = '';
  playerEmail: string = '';
  playerAgent: string = '';
  playerSubdistributer: string = '';
  playerDistributer: string = '';
  playerPassword: string = '';
  pathname: any;
  agents: any;
  distributors: any;
  subdisUsername: string = '';
  subdisEmail: string = '';
  subdisPassword: string = '';
  subdisDistributer: string = '';
  subdisCommision: string = '';
  subdisCredit: string = '';
  subdisName: string = '';
  subdistributors: any;
  agentUsername: string = '';
  agentName: string = '';
  agentEmail: string = '';
  agentPassword: string = '';
  agentsubdisDistributer: string = '';
  agentCommision: string = '';
  agentCredit: string = '';
  fake: any = '';
  amount: any = "";
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
    this.db.list('15roulate/agents').valueChanges().subscribe((agents:any) => {
      this.agents = agents;
    })
    this.db.list('15roulate/distributors').valueChanges().subscribe(distributors => {
      this.distributors = distributors;
    })
    this.db.list('15roulate/subdistributer').valueChanges().subscribe(subdistributors => {
      this.subdistributors = subdistributors;
    })
  }
  ngAfterViewInit() { }

  adddistributers() {
    if (this.username == '') {
      alert('please enter username')
      return
    }
    if (this.email == '') {
      alert('please enter email')
      return
    }
    if (this.email == '') {
      alert('please enter email')
      return
    }
    if (this.password == '') {
      alert('please enter password')
      return
    }
    if (this.name == '') {
      alert('please enter name')
      return
    }

    const ditcommision = '0';
    const credit = '0';
    this.pathname = window.location.pathname;

    if (this.adminuid) {
      this.authService.disregister(this.email, this.password, ditcommision, this.adminuid, this.name, this.username, credit, this.pathname);
    } else {
      alert('admin');
    }


  }

  addplayer() {
    if (this.playerUsername == '') {
      alert('please enter Player username')
      return
    }
    if (this.playerEmail == '') {
      alert('please enter Player Email')
      return
    }
    if (this.playerAgent == '') {
      alert('please enter Select Agent')
      return
    }
    if (this.playerPassword == '') {
      alert('please enter Player password');
      return
    }
    if (this.adminuid) {
      this.db.object('15roulate/agents/'+this.playerAgent).valueChanges().subscribe((data:any)=>{
        this.authService.playerregister(this.playerEmail, this.playerPassword, this.playerAgent, data.subdistributor, data.distributor, this.playerUsername, this.pathname, this.adminuid)
      })
      
    }
  }

  addsubdistributor() {
    if (this.subdisUsername == '') {
      alert('please enter Subdistributer username');
      return
    }
    if (this.subdisEmail == '') {
      alert('please enter Subdistributer Email ');
      return
    }
    if (this.subdisPassword == '') {
      alert('please Enter Subdistrubuter password');
      return
    }
    if (this.subdisDistributer == '') {
      alert('please select Distributer ');
      return
    }
    if (this.subdisCredit == '') {
      alert('please enter Credits ');
      return
    }
    if (this.subdisCommision == '') {
      alert('please enter Comision ');
      return
    }
    if (this.subdisName == '') {
      alert('please enter Name ');
      return
    }
    if (this.adminuid) {
      console.log(this.adminuid);
      this.authService.subdisregister(this.subdisEmail, this.subdisPassword, this.subdisCommision, this.adminuid, this.subdisName, this.subdisUsername, this.subdisCredit, this.pathname, this.subdisDistributer);
    }
  }

  addagents() {
    if (this.agentUsername == '') {
      alert('please enter agent username');
      return
    }
    if (this.agentEmail == '') {
      alert('please enter agent Email ');
      return
    }
    if (this.agentPassword == '') {
      alert('please Enter agent password');
      return
    }
    if (this.agentsubdisDistributer == '') {
      alert('please select Subdistributor ');
      return
    }
    if (this.agentCredit == '') {
      alert('please enter Credits ');
      return
    }
    if (this.agentCommision == '') {
      alert('please enter Comision ');
      return
    }
    if (this.agentName == '') {
      alert('please enter Name ');
      return
    }
    if (this.adminuid) {
      this.agentsubdisDistributer = this.agentsubdisDistributer.replace(/\s+/g, '');
      this.db.object('15roulate/subdistributer/' + this.agentsubdisDistributer).valueChanges().subscribe((data: any) => {
        this.fake = data.distributor;
      })
      if (this.fake != '') {
        this.authService.agentregister(this.agentEmail, this.agentPassword, this.agentCommision, this.adminuid, this.agentName, this.agentUsername, this.agentCredit, this.pathname, this.fake, this.agentsubdisDistributer);
        this.fake = '';
      }

    }
  }

  sendrequest() {
    if (this.amount == '') {
      alert('Please Enter Amount');
      return;
    }
    const id = '';
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);
    const currentDate = `${year}-${month}-${day}`;
    if (localStorage.getItem('role') == 'distributor') {
      this.db.object('15roulate/distributors/' + localStorage.getItem('uid')).valueChanges().subscribe((data: any) => {
        this.fake = data.admin;
        const uid = this.db.createPushId();
        this.db.object('15roulate/requests/' + uid).set({
          date: currentDate,
          adminId: this.fake,
          requester: localStorage.getItem('uid'),
          requestAmount: this.amount,
          id: uid,
          request: 'pending'
        }).then(() => {
          this.db.object('15roulate/distributors/' + localStorage.getItem('uid')).update({
            request: '1'
          }).then(() => {
            alert('request sent successfully');
            this.fake = '';
          })
        })
      })
    }
    if (localStorage.getItem('role') == 'subdistributor') {
      this.db.object('15roulate/subdistributer/' + localStorage.getItem('uid')).valueChanges().subscribe((data: any) => {
        this.fake = data.distributor;
        const uid = this.db.createPushId();
        this.db.object('15roulate/requests/' + uid).set({
          date: currentDate,
          distributorId: this.fake,
          requester: localStorage.getItem('uid'),
          requestAmount: this.amount,
          id: uid,
          request: 'pending'
        }).then(() => {
          this.db.object('15roulate/subdistributer/' + localStorage.getItem('uid')).update({
            request: '1'
          }).then(() => {
            alert('request sent successfully');
            this.fake = '';
          })

        })
      })
    }

    if (localStorage.getItem('role') == 'agent') {
      this.db.object('15roulate/agents/' + localStorage.getItem('uid')).valueChanges().subscribe((data: any) => {
        this.fake = data.subdistributor;
        const uid = this.db.createPushId();
        this.db.object('15roulate/requests/' + uid).set({
          date: currentDate,
          subdistributorId: this.fake,
          requester: localStorage.getItem('uid'),
          requestAmount: this.amount,
          id: uid,
          request: 'pending'
        }).then(() => {
          this.db.object('15roulate/subdistributer/' + localStorage.getItem('uid')).update({
            request: '1'
          }).then(() => {
            alert('request sent successfully');
            this.fake = '';
          })

        })
      })
    }
  }
  getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    const paddedMonth = ('0' + month).slice(-2);
    const paddedDay = ('0' + day).slice(-2);
    const paddedHours = ('0' + hours).slice(-2);
    const paddedMinutes = ('0' + minutes).slice(-2);
    const paddedSeconds = ('0' + seconds).slice(-2);
    const paddedMilliseconds = ('00' + milliseconds).slice(-3);

    const dateTimeString = `${year}${paddedMonth}${paddedDay}${paddedHours}${paddedMinutes}${paddedSeconds}${paddedMilliseconds}`;
    return dateTimeString;
  }
}
