import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
adminId:any;
  constructor(private fireauth: AngularFireAuth, private router: Router, private database: AngularFireDatabase) { }


  // login
  login(email: string, password: string) {
    this.fireauth.signInWithEmailAndPassword(email, password).then((res) => {
      const uid = res.user?.uid;
      const userRef = this.database.object(`15roulate/admin/${uid}`);
      userRef.valueChanges().subscribe((userData: any) => {
        // Do something with the user's data
        const data = userData;
        if (data == null) {
          const userRef = this.database.object(`15roulate/distributors/${uid}`);
          userRef.valueChanges().subscribe((userData: any) => {
            const data = userData;
            console.log(data);
            if (data == null) {
              const userRef = this.database.object(`15roulate/subdistributer/${uid}`);
              userRef.valueChanges().subscribe((userData: any) => {
                const data = userData;
                if (data == null) {
                  const userRef = this.database.object(`15roulate/agents/${uid}`);
                  userRef.valueChanges().subscribe((userData: any) => {
                    const data = userData;
                    if (data == null) {
                      alert('user is not admin check again and login');
                      this.router.navigate(['/login']);
                    } else {
                      if(data.blocked == true){
                        alert('you are blocked by admin contact them');
                        this.router.navigate(['/login']);
                      }else{
                      localStorage.setItem('token', 'true');
                      localStorage.setItem('uid', data.id);
                      localStorage.setItem('role', data.role);
                      this.router.navigate(['/dashboard']);
                    }
                    }
                  })

                } else {
                  if(data.blocked == true){
                    alert('you are blocked by admin contact them');
                    this.router.navigate(['/login']);
                  }else{
                  localStorage.setItem('token', 'true');
                  localStorage.setItem('role', data.role);
                  localStorage.setItem('uid', data.id);
                  this.router.navigate(['/dashboard']);
                }
                }
              })
            } else {
              if(data.blocked == true){
                alert('you are blocked by admin contact them');
                this.router.navigate(['/login']);
              }else{
              localStorage.setItem('token', 'true');
              localStorage.setItem('role', data.role);
              localStorage.setItem('uid', data.id);
             
              this.router.navigate(['/dashboard']);
            }
            }
          })
        } else {
          localStorage.setItem('token', 'true');
          localStorage.setItem('role', data.role);
          localStorage.setItem('uid', data.id);
          this.router.navigate(['/dashboard']);
        }
      });



      // this.router.navigate(['/dashboard']);
    }, err => {
      alert('something went wrong');
      this.router.navigate(['/login']);
    })
  }

  // register

  register(email: string, password: string, role: string) {
    this.fireauth.createUserWithEmailAndPassword(email, password).then((res) => {
      const uid = res.user?.uid;
      this.database.object('15roulate/admin/' + uid).set({
        email: email,
        role: role
      }).then(() => {
        alert('Registration successful');
        this.router.navigate(['/login']);
      });
    }, err => {
      alert('Something went wrong');
      this.router.navigate(['/register']);
    });
  }


  // distributrregister
  disregister(email: string, password: string, commision: string, admin: string, name: string, username: string, credit: string, path: any) {
    this.fireauth.createUserWithEmailAndPassword(email, password).then((res) => {
      const uid = res.user?.uid;
      this.database.object('15roulate/distributors/' + uid).set({
        email: email,
        username: username,
        commision: commision,
        credit: credit,
        name: name,
        admin: admin,
        id: uid,
        blocked: false,
        request: '0',
        mode: 'high',
        role: 'distributor',
      }).then(() => {
        alert('Registration successful');
        this.router.navigate(["'" + path + "'"]);
      });
    }, err => {
      alert('Something went wrong');
      this.router.navigate(["'" + path + "'"]);
    });
  }

  // subdistributrregister

  subdisregister(email: string, password: string, commision: string, admin: string, name: string, username: string, credit: string, path: any, distributor: string) {
    this.fireauth.createUserWithEmailAndPassword(email, password).then((res) => {
      const uid = res.user?.uid;
      this.database.object('15roulate/subdistributer/' + uid).set({
        email: email,
        username: username,
        commision: commision,
        credit: credit,
        name: name,
        admin: admin,
        distributor: distributor,
        id: uid,
        blocked: false,
        request: '0',
        mode: 'high',
        role: 'subdistributor',
      }).then(() => {
        alert('Registration successful');
        this.router.navigate(["'" + path + "'"]);
      });
    }, err => {
      alert('Something went wrong');
      this.router.navigate(["'" + path + "'"]);
    });
  }
  // subdistributrregister

  agentregister(email: string, password: string, commision: string, admin: string, name: string, username: string, credit: string, path: any, distributor: string, subdistributor: string) {
    this.fireauth.createUserWithEmailAndPassword(email, password).then((res) => {
      const uid = res.user?.uid;
      this.database.object('15roulate/agents/' + uid).set({
        email: email,
        username: username,
        commision: commision,
        credit: credit,
        name: name,
        admin: admin,
        distributor: distributor,
        subdistributor: subdistributor,
        id: uid,
        blocked: false,
        request: '0',
        mode: 'high',
        role: 'agent',
      }).then(() => {
        alert('Registration successful');
        this.router.navigate(["'" + path + "'"]);
      });
    }, err => {
      alert('Something went wrong');
      this.router.navigate(["'" + path + "'"]);
    });
  }
  //   player register


  playerregister(email: string, password: string, agentId: string, subDistId: string, distId: string, username: string, path: any, admin: string) {
    this.fireauth.createUserWithEmailAndPassword(email, password).then((res) => {
      const uid = res.user?.uid;
      this.database.object('15roulate/users/' + uid).set({
        email: email,
        username: username,
        admin: admin,
        distributor: distId,
        agentId: agentId,
        subdistributor: subDistId,
        id: uid,
        blocked: false,
        request: '0',
        balance:'0',
        withdrawl: '0'
      }).then(() => {
        alert('Registration successful');
        this.router.navigate(["'" + path + "'"]);
      });
    }, err => {
      alert('Something went wrong');
      this.router.navigate(["'" + path + "'"]);
    });
  }
  // logout 

  logout() {
    this.fireauth.signOut().then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      localStorage.removeItem('role');
      this.router.navigate(['/login']);
    }, err => {
      alert(err.message);
    })
  }


  // get uid 
  getUserId() {
    return this.fireauth.currentUser;
    
  }
}