import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'app-subdistributor',
  templateUrl: './subdistributor.component.html',
  styleUrls: ['./subdistributor.component.css']
})
export class SubdistributorComponent implements OnInit {

  users: any;
  collection:any;
  role:any;
  collectionId:any=localStorage.getItem('uid');
  constructor(private db: AngularFireDatabase) { }

  ngOnInit(): void {
    if(localStorage.getItem('role') == 'admin'){
      this.role = 'admin';
    }
    if(localStorage.getItem('role') == 'distributor'){
      this.role = 'distributor';
    }
    
    this.users = this.db.list('15roulate/subdistributer', ref => ref.orderByChild(this.role).equalTo(this.collectionId)).valueChanges();
  }

  block(uid: string,status:boolean) {
    this.collection = '15roulate/subdistributer/'+uid;
    this.collection = this.collection.replace(/\s+/g, '');
    if(this.collection){
      this.db.object(`${this.collection}`).update({
        blocked: status,
      }).then(() => {
        alert('Data updated successfully!');
      }).catch((error) => {
        console.error(error);
      });
    }
   
  }

}
