<form >
    <div>
      <label>Username:</label>
      <input type="text"  name="username" required [(ngModel)]="username">
    </div>
    <div>
      <label>Email:</label>
      <input type="email"  name="email" required [(ngModel)]="email">
    </div>
    <div>
      <label>Password:</label>
      <input type="password" name="password" required [(ngModel)]="password">
    </div>
    <div>
      <label>Role:</label>
      <input type="text" name="role" required [(ngModel)]="role">
    </div>
    <button type="submit" (click)="register()">Register</button>
  </form>
  