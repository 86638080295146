// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA-9D9bV5DqGb_Md5brSkFRS7ld58WMBtw",
    authDomain: "roulette-aee9f.firebaseapp.com",
    databaseURL: "https://roulette-aee9f-default-rtdb.firebaseio.com",
    projectId: "roulette-aee9f",
    storageBucket: "roulette-aee9f.appspot.com",
    messagingSenderId: "775812481325",
    appId: "1:775812481325:web:8757049359bb3c8c50d3a4"
  }
};
