  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 mt-5">
        <div class="card">
          <div class="card-header">
            <h4>Login</h4>
          </div>
          <div class="card-body">
            <form>
              <div class="form-group">
                <label for="username">Email</label>
                <input type="text" class="form-control" id="username"  type="email" name="email" [(ngModel)]="email" required>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" type="password" name="password" [(ngModel)]="password" required>
              </div>
              <button  style="margin-top: 20px;"type="submit" class="btn btn-primary btn-block" (click)="login()">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  